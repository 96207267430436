<template>
	<div id="ap">
		<a-modal v-model="visible" :title="`${name}的积分记录`" :width="800" :footer="null" centered destroyOnClose>
			<a-table :scroll="{ x: '100%' }" :pagination="false" :style="{ background: '#FFF' }" size="small"
				row-key="id" :columns="keys" ref="list" :data-source="data.records">
				<template slot-scope="row" slot="number">
					<div style="color:#f00" v-if="row.type == '获得'">+ {{ row.grade }}</div>
					<div style="color:#090" v-else>- {{ row.grade }}</div>
				</template>
				<template slot-scope="row" slot="remark">
					<a-tooltip :title="row.remark">
						<span>{{ row.remark }}</span>
					</a-tooltip>
				</template>
			</a-table>
			<a-row>
				<a-col span="24" style="text-align: center; padding: 20px 0;">
					<a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext"
						@showSizeChange="setLimit" :current="data.current" show-size-changer size="small"
						:show-total="total => `共 ${total} 条数据`">
						<template slot="buildOptionText" slot-scope="props">
							<span>{{ props.value }}条/页</span>
						</template>
					</a-pagination>
				</a-col>
			</a-row>
		</a-modal>

	</div>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import utils from '@/common/utils';
export default {
	data() {
		return {
			loading: false,
			data: [],
			name: '',
			visible: false,
			param: {
				page: 1,
				limit: 10,
				keyword: '',
				staffId: ''
			},
			keys: [
				// { title: '#',  scopedSlots: { customRender: 'number' }, width: 60, align: 'center', fixed: 'left' },
				// { title: 'ID', dataIndex: 'id', width: 100, align: 'center', fixed: 'left' },
				{ title: '发生时间', dataIndex: 'createTime', width: 160, align: 'center', align: 'left', ellipsis: true },
				{ title: '变动数量', scopedSlots: { customRender: 'number' }, align: 'center', width: 120, ellipsis: true },
				{ title: '账户余额', dataIndex: 'after', align: 'center', width: 120, ellipsis: true },
				{ title: '备注说明', scopedSlots: { customRender: 'remark' }, align: 'left', ellipsis: true, tooltip: true }
			]
		};
	},
	methods: {
		...apiUtil,
		_show(name, staffId) {
			this.name = name;
			this.param.staffId = staffId;
			this.doRefresh();
		},
		doRefresh() {
			this.param.page = 1;
			this.getList();
		},
		// 获取数据
		getList() {
			this.loading = true;
			this.http
				.get('/platform/study/staff/grade/detail', this.param)
				.then(ret => {
					// console.log(ret)
					if (this.param.page == 1) {
						this.visible = true;
					}
					this.data = ret.data;
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
				});
		},
		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getList();
		}
	}
};
</script>

<style scoped></style>
